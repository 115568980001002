import React from "react";

const ZapLandingPage = () => {
  return (
    <div className="flex flex-col min-h-screen bg-black text-white">
      {/* Main content */}
      <main className="flex-grow flex items-center justify-center p-4 relative">
        <div className="absolute inset-0 flex items-center justify-center">
          <div className="w-full h-full sm:w-[400px] sm:h-[400px] animate-gradient-shift"></div>
        </div>
        <div className="relative z-10 max-w-3xl w-full text-center">
          <h1 className="text-4xl sm:text-6xl font-bold mb-2 sm:mb-6 font-sora">
            Zap
          </h1>
          <h2 className="text-3xl sm:text-5xl font-bold mb-2 sm:mb-4 leading-tight font-satoshi">
            The future of mobility
            <br />
            is up in the air!
          </h2>
          <p className="text-sm sm:text-xl mb-4 sm:mb-8 text-gray-300 font-satoshi">
            We're readying affordable aerial mobility for everyone and the
            everyday.
          </p>
          <a
            href="mailto:hello@flyzap.co"
            className="inline-block px-6 py-2 text-sm font-semibold text-black bg-white rounded-full hover:bg-gray-100 transition duration-300 ease-in-out transform hover:scale-105 focus:outline-none focus:ring-2 focus:ring-gray-400 focus:ring-opacity-50"
          >
            Say Hello 👋
          </a>
        </div>
      </main>

      {/* Footer */}
      <footer className="p-4 text-center text-xs sm:text-sm text-gray-500 font-satoshi mt-auto footer-ios-fix">
        <span>© 2024 Velozity, Inc. All rights reserved.</span>
        <br />
        Palo Alto, CA
      </footer>

      <style jsx>{`
        @import url("https://fonts.googleapis.com/css2?family=Sora:wght@400;700&display=swap");
        @import url("https://api.fontshare.com/v2/css?f[]=satoshi@400,700&display=swap");

        .font-sora {
          font-family: "Sora", sans-serif;
        }
        .font-satoshi {
          font-family: "Satoshi", sans-serif;
        }

        @keyframes gradient-shift {
          0%,
          100% {
            background-position: 0% 50%;
          }
          50% {
            background-position: 100% 50%;
          }
        }

        .animate-gradient-shift {
          background: linear-gradient(270deg, #3a1c71, #d76d77, #ffaf7b);
          background-size: 300% 300%;
          animation: gradient-shift 15s ease infinite;
          opacity: 0.6;
          filter: blur(100px);
        }

        @media (max-width: 640px) {
          .animate-gradient-shift {
            background: radial-gradient(circle, #3a1c71, #d76d77, #ffaf7b);
            background-size: 100% 100%;
            animation: mobile-gradient-shift 20s ease infinite;
          }

          @keyframes mobile-gradient-shift {
            0%,
            100% {
              transform: translate(-25%, -25%) rotate(0deg);
            }
            50% {
              transform: translate(-25%, -25%) rotate(180deg);
            }
          }

          .footer-ios-fix {
            margin-bottom: -3rem; /* Adjust this value as needed */
          }
        }
      `}</style>
    </div>
  );
};

export default ZapLandingPage;
