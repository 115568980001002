import React from "react";
import ZapLandingPage from "./ZapLandingPage";
import { Analytics } from "@vercel/analytics/react";

function App() {
  return (
    <div>
      <ZapLandingPage />
      <Analytics />
    </div>
  );
}

export default App;
